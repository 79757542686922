<template>
  <div v-if="$store.state.launchedEmulator" v-show="$store.state.visibledEmulator" id="emulator">
      <img src="/icons/close.png" class="close-btn" @click="hideEmulator" />
      <iframe width="100%" height="100%" src="/v86/emulator.html" scrolling="no" />
  </div>
</template>

<script>
export default {
  methods: {
    async hideEmulator () {
      this.$store.commit("hideEmulator")
    }
  }
}
</script>

<style scoped>
.emulator {
    width: 100%;
	height: 320px;
    position: fixed;
    bottom: 0;
}
iframe {
    vertical-align: bottom;
}

.close-btn {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    margin: 5px;
    cursor : pointer;
    opacity: 0.7;
}
</style>
