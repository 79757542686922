import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/components/Home'
import Stage from '@/components/Stage'


const router = new createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/stage/:category/:course/:page',
      name: 'Stage',
      component: Stage
    }
  ]
})

export default router
