import { createStore } from 'vuex'

const store = createStore({
  state: {
      launchedEmulator: false,
      visibledEmulator: false
  },
  mutations: {
      launchEmulator (state) {
          state.launchedEmulator = true
          state.visibledEmulator = true
      },
      hideEmulator (state) {
          state.visibledEmulator = false
      },
  }
})

export default store
