<template>
    <footer v-if="!$store.state.visibledEmulator" class="footer d-flex flex-column footer-column">
        <span class="border-top"></span>
        <div class="d-flex footer-item">
            <div class="align-self-center px-2">
                Home:
                <a style="color: inherit;" class="px-1" href="https://celtf.com" target="_blank">CELTF</a>
            </div>
            <div class="align-self-center px-2">
                Developers:
                <a style="color: inherit;" class="px-1" href="https://github.com/fealone" target="_blank">fealone</a>
            </div>
        </div>
        <span class="border-top"></span>
        <div class="d-flex footer-item">
            <div class="mr-auto align-self-center px-2">
                Copyright © {{year}} fealone. All rights reserved.
            </div>
            <div class="ml-auto align-self-center px-2">
                <a style="color: inherit;" href="https://celtf.com/docs/privacy-policy" target="_blank">PrivacyPolicy</a>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    data: function() {
        return {year: new Date().getFullYear()}
    }
}
</script>

<style scoped>
.footer-item {
    height: 30px;
}

.footer-column {
    height: 60px;
    width: 100%;
}
</style>
