<template>
  <div id="app">
    <div class="header">
        <pageHeader />
    </div>
    <div class="body">
        <div class="main">
          <router-view />
        </div>
    </div>
    <div>
      <Emulator class="emulator"/>
    </div>
    <div class="footer">
        <pageFooter />
    </div>
  </div>
</template>

<script>
import PageHeader from './components/PageHeader.vue'
import PageFooter from './components/PageFooter.vue'
import Emulator from './components/Emulator.vue'

export default {
  name: 'App',
  components: {
    PageHeader,
    PageFooter,
    Emulator,
  }
}
</script>

<style>
#app {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.body {
  display: flex;
  flex: 1;
}

.sidebar {
  height: 320px;
}

.main {
  flex: 1;
}

.footer {
  margin-top: auto;
  display: flex;
  bottom: 0;
  width: 100%;
  background-color: #f8f9fa;
  color: #777;
}
</style>
